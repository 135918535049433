<template>
  <b-row>
    <div class="notification-item-container">
        <div class="notification-item-content">
            <span :class="notification.readed ? 'notification-item-title-readed' : 'notification-item-title-unread'">
                <!-- {{ notification.title }}  -->
                Comentário 
            </span>
            <span :class="notification.readed ? 'notification-item-message-readed' : 'notification-item-message-unread'">
                {{ notification.comment.comment }}
            </span>
            <div @click="markRead(notification)" class="btn-go-to-comment">
                {{ $t('notification.see_notification') }}
                <svg version="1.1" id="lni_lni-chat-alt-8" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" fill="#555" xml:space="preserve">
                    <g>
                        <path d="M39.3,5.3H24.8C11.8,5.3,1.3,15.5,1.3,28c0,12.6,8.2,21.4,28.3,30.5c0.4,0.2,0.8,0.3,1.2,0.3c0.6,0,1.1-0.2,1.6-0.5
                            c0.9-0.6,1.4-1.5,1.4-2.5v-5h5.5c13,0,23.5-10.2,23.5-22.7C62.8,15.5,52.2,5.3,39.3,5.3z M39.3,47.2h-6c-1.7,0-3,1.3-3,3V55
                            C11.9,46.6,4.8,39,4.8,28c0-10.6,9-19.2,20-19.2h14.5c11,0,20,8.6,20,19.2S50.3,47.2,39.3,47.2z"/>
                        <path d="M17.3,26.1c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C19.8,27.2,18.7,26.1,17.3,26.1z"/>
                        <path d="M32,26.1c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C34.5,27.2,33.4,26.1,32,26.1z"/>
                        <path d="M46.7,26.1c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C49.2,27.2,48.1,26.1,46.7,26.1z"/>
                    </g>
                </svg>

                <!-- <img class="ml-1" src="../../icons/chat-alt-8.svg" style="width: 16px;" alt="chat-alt-8"> -->
            </div>
        </div>
        <div>
            <b-button id="btn-mark-read" v-if="!notification.readed" v-b-tooltip.hover variant="text" @click="markAsRead(notification)">
                <svg version="1.1" id="lni_lni-chat-check-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                    <g>
                        <path d="M53.7,9.9c-6.6-6.5-15.6-9.7-24.9-8.8C14.6,2.7,3.1,14.1,1.7,28.4C1,35.1,2.4,41.6,5.8,47.3L1.5,58.5
                            c-0.5,1.3-0.2,2.7,0.9,3.6C3,62.7,3.8,63,4.6,63c0.5,0,1-0.1,1.5-0.4l9.9-5c5.7,3.5,12.3,5.1,19.1,4.4
                            c14.5-1.4,26.1-12.9,27.5-27.3C63.5,25.5,60.3,16.5,53.7,9.9z M59.1,34.4c-1.3,12.8-11.5,23-24.4,24.2c-6.3,0.6-12.5-0.9-17.7-4.4
                            c-0.5-0.3-1.2-0.4-1.8-0.1L4.9,59.3l4.5-11.6c0.2-0.5,0.1-1.1-0.2-1.6C5.9,41,4.5,34.9,5.1,28.7C6.5,16.1,16.6,6,29.2,4.7
                            c1-0.1,1.9-0.1,2.9-0.1c7.2,0,14.1,2.8,19.2,7.9C57.1,18.2,59.9,26.2,59.1,34.4z"/>
                        <path d="M40.9,22l-13,13l-4.8-4.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l6,6c0.3,0.3,0.8,0.5,1.2,0.5
                            c0.4,0,0.9-0.2,1.2-0.5l14.3-14.3c0.7-0.7,0.7-1.8,0-2.5C42.7,21.3,41.6,21.3,40.9,22z"/>
                    </g>
                </svg>
                <b-tooltip target="btn-mark-read" triggers="hover">
                    {{ $t('notification.mark_as_read') }}
                </b-tooltip>
            </b-button>
        </div>
    </div>
  </b-row>
</template>

<script>
    export default {
        name: "Notifications-item",
        props: {
            notification: {
                type: Object,
                required: true
            },
            markAsRead: {
                type: Function,
                default: () => {}
            },
            markAllAsRead: {
                type: Function,
                default: () => {}
            },
        },
        methods: {
            markRead(notification) {
                this.markAsRead(notification, true);
            }
        }
    }
</script>

<style>
    .notification-item-container {
        border: 1px solid #d4d2d2;
        display: flex;
        width: 100%;
        color: #555;
        border-radius: 12px;
        padding: 12px;
        padding-bottom: 0px;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 12px;
    }
    #btn-mark-read svg {
        fill: var(--maincolor);
        width: 20px;
        height: 20px;
    }
    .notification-item-content {
        display: flex;
        width: 100%;
        padding: 6px;
        flex-direction: column;
        border-radius: 12px;
    }
    .notification-item-title-readed {
        font-size: 1rem;
        font-weight: 400;
        color: rgb(146, 144, 144);
    }
    .notification-item-title-unread {
        font-size: 1rem;
        font-weight: bold;
        color: #666;
    }
    .notification-item-message-readed {
        font-size: 0.7rem;
        max-height: 200px;
        overflow: hidden;
        font-weight: 300;
        color: #333;
    }
    .notification-item-message-unread {
        font-size: 0.7rem;
        max-height: 200px;
        max-width: 160px;
        overflow: hidden;
        font-weight: 500;
        color: #333;
    }
    .btn-go-to-comment {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 500;
        color: #666;
        cursor: pointer;
        margin-top: 24px;
        padding-bottom: 12px;
    }
    .btn-go-to-comment svg {
        fill: #555;
        width: 16px;
        margin-left: 6px;
    }
</style>