<template>
    <b-container fluid>
        <b-row>
            <div class="spacer">
                <b-button variant="text" style="text-decoration: none;" id="popover-target-1">
                    <svg v-if="notifications.length"  version="1.1" id="lni_lni-bell-alt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                        <g>
                            <path d="M49.7,37.2v-9.7c0-9.2-7-16.7-15.9-17.6V5.1c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8V10c-8.9,0.9-15.9,8.4-15.9,17.6
                                l0,9.6c0,0-0.3,4.3-1.7,7.9c-0.8,1.9-0.5,4,0.6,5.6c1.1,1.7,3,2.6,5,2.6h5.5c0.5,4.1,4.1,7.3,8.3,7.3c4.3,0,7.8-3.2,8.3-7.3h5.5
                                c2,0,3.9-1,5-2.6c1.1-1.7,1.3-3.8,0.6-5.6C49.9,41.4,49.7,37.2,49.7,37.2z M32,57.1c-2.3,0-4.3-1.6-4.8-3.8h9.5
                                C36.3,55.5,34.3,57.1,32,57.1z M47.9,48.7c-0.5,0.7-1.2,1.1-2.1,1.1H25.5c0,0,0,0,0,0c0,0,0,0,0,0h-7.3c-0.8,0-1.6-0.4-2.1-1.1
                                c-0.5-0.7-0.6-1.6-0.2-2.3c1.7-4.2,2-8.9,2-9.2v-9.7c0-7.8,6.4-14.2,14.2-14.2s14.2,6.4,14.2,14.2l0,9.8c0,0.2,0.3,4.9,2,9.1
                                C48.5,47.2,48.4,48,47.9,48.7z"/>
                            <path d="M11.9,7.1c-0.7-0.7-1.8-0.6-2.5,0.1c-5.3,5.7-8.2,13.1-8.2,20.9c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
                                c0-6.9,2.6-13.5,7.3-18.5C12.7,8.9,12.7,7.8,11.9,7.1z"/>
                            <path d="M54.5,7.2c-0.7-0.7-1.8-0.8-2.5-0.1c-0.7,0.7-0.8,1.8-0.1,2.5c4.7,5.1,7.3,11.6,7.3,18.5c0,1,0.8,1.8,1.8,1.8
                                s1.8-0.8,1.8-1.8C62.8,20.3,59.8,12.9,54.5,7.2z"/>
                        </g>
                    </svg>
                    <svg v-else version="1.1" id="lni_lni-bell-alt-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                        y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                        <path d="M52.8,46.1c-1.6-3.9-1.9-8.5-1.9-8.5V27.2c0-9.8-7.6-17.9-17.2-18.8V3c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v5.4
                            c-9.6,0.9-17.2,9-17.2,18.8l0,10.3c0,0-0.3,4.6-1.9,8.6c-0.8,2-0.6,4.2,0.6,5.9c1.2,1.8,3.1,2.8,5.2,2.8h6.1
                            c0.5,4.4,4.3,7.9,8.9,7.9c4.6,0,8.3-3.5,8.9-7.9H47c2.1,0,4.1-1,5.2-2.8C53.4,50.3,53.6,48.1,52.8,46.1z M32,59.3
                            c-2.6,0-4.8-1.9-5.3-4.4h10.7C36.8,57.4,34.6,59.3,32,59.3z M49.3,50.1c-0.5,0.8-1.4,1.2-2.3,1.2H25c0,0,0,0,0,0c0,0,0,0,0,0H17
                            c-1,0-1.8-0.5-2.3-1.2c-0.5-0.8-0.6-1.8-0.3-2.7c1.8-4.5,2.1-9.5,2.2-9.8V27.2c0-8.5,6.9-15.4,15.4-15.4s15.4,6.9,15.4,15.4l0,10.5
                            c0,0.2,0.3,5.2,2.1,9.7C49.9,48.4,49.8,49.3,49.3,50.1z"/>
                    </svg>
                </b-button>
                <b-popover class="shadow-none" style="border-radius: 24px; min-width: none;" target="popover-target-1" triggers="hover" placement="right">
                    <template #title>
                        <div class="title-notification">
                            <span style="width: 100%; margin-top: 3px;">
                                {{ $t('notification.notifications') }} ({{ notifications.length }})
                            </span>
                            <b-button class="mark-all-as-read shadow-none" v-b-tooltip.hover :title="$t('notification.mark_all_as_read')" variant="text" @click="markAllAsRead">
                                <svg version="1.1" id="lni_lni-chat-check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                    y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                                    <g>
                                        <path d="M53.7,9.9c-6.6-6.5-15.6-9.7-24.9-8.8C14.6,2.7,3.1,14.1,1.7,28.4C1,35.1,2.4,41.6,5.8,47.3L1.5,58.5
                                            c-0.5,1.3-0.2,2.7,0.9,3.6C3,62.7,3.8,63,4.6,63c0.5,0,1-0.1,1.5-0.4l9.9-5c5.7,3.5,12.3,5.1,19.1,4.4
                                            c14.5-1.4,26.1-12.9,27.5-27.3C63.5,25.5,60.3,16.5,53.7,9.9z M59.1,34.4c-1.3,12.8-11.5,23-24.4,24.2c-6.3,0.6-12.5-0.9-17.7-4.4
                                            c-0.5-0.3-1.2-0.4-1.8-0.1L4.9,59.3l4.5-11.6c0.2-0.5,0.1-1.1-0.2-1.6C5.9,41,4.5,34.9,5.1,28.7C6.5,16.1,16.6,6,29.2,4.7
                                            c1-0.1,1.9-0.1,2.9-0.1c7.2,0,14.1,2.8,19.2,7.9C57.1,18.2,59.9,26.2,59.1,34.4z"/>
                                        <path d="M40.9,22l-13,13l-4.8-4.8c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l6,6c0.3,0.3,0.8,0.5,1.2,0.5
                                            c0.4,0,0.9-0.2,1.2-0.5l14.3-14.3c0.7-0.7,0.7-1.8,0-2.5C42.7,21.3,41.6,21.3,40.9,22z"/>
                                    </g>
                                </svg>
                            </b-button>
                        </div>
                    </template>
                    <div class="notifications-container">
                        <div v-if="notifications.length">
                            <notification-item :markAsRead="markAsRead" :markAllAsRead="markAllAsRead" v-for="notification in notifications" :key="notification.id" :notification="notification" />
                        </div>
                        <div v-else>
                            <p class="text-center">{{ $t('notification.no_notifications') }}</p>
                        </div>
                    </div>
                </b-popover>
            </div>
       </b-row>
    </b-container>
</template>

<script>
    import NotificationItem from './notification-item/index.vue';
    import NotifyService from "@/services/resources/NotifyService";
    const serviceNotify = NotifyService.build();
    export default {
        name: "Notifications",
        components: {
            NotificationItem
        },
        data() {
            return {
                hasNotifications: false,
                notifications: [
                ]
            }
        },
       methods: {
        markAsRead(notify, goTo) {
            this.notifications = this.notifications.map(notification => {
                if (notification.id === notify.id) {
                    notification.viewed = true;
                } else {
                    return notification;
                }
            });
            this.loading = true;
            serviceNotify
                .postID({ id: notify.id, viewed: true })
                .then(() => {
                    this.loading = false;
                    this.fetchNotifies();
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            if (goTo) {
                this.goToNotification(notify);
            }
            
        },
        markAllAsRead() {
            this.notifications = this.notifications.map(notification => {
                notification.viewed = true;
                return notification;
            });
            this.loading = true;
            serviceNotify
                .create({})
                .then(() => {
                    this.loading = false;
                    this.fetchNotifies();
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                })
        },
        goToNotification(notify) {
            this.$router.push(`/curso/${notify.course_id}/modulo/${notify.module_id}/aula/${notify.lesson_id}?c_id=${notify.lesson_comment_id}`);
        },
        fetchNotifies() {
            this.loading = true;
            serviceNotify
                .search()
                .then((resp) => {
                    this.notifications = resp.data;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        }
       },
       created() {
        this.fetchNotifies();
       }
    }
</script>

<style>
    .spacer {
        display: flex;
        width: 100%;
        padding-right: 24px;
        justify-content: flex-end !important;
    }
    .title-notification {
        display: flex;
        height: 40px;
        width: 100%;
        min-width: 280px;
        color: var(--maincolor);
        border-radius: 12px;
        padding: 20px;
        padding-right: 20px;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
    }
    #__bv_popover_66__ {
      min-width: 320px !important;
    }
    .mark-all-as-read svg {
        fill: var(--maincolor);
        width: 20px;
    }
    .notifications-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        padding-right: 20px;
        min-height: 20vh;
        max-height: 50vh;
        overflow: auto;
        overflow-x: hidden;
    }
    .notifications-container::-webkit-scrollbar {
        width: 6px;
    }

    .notifications-container::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 3px;
    }

    .notifications-container::-webkit-scrollbar-thumb {
        background-color: #dbd9d9;
        border-radius: 5px;
        border: 1px solid #e6e5e5;
    }
    #popover-target-1 svg {
        fill: var(--maincolor);
        width: 30px;
        margin-right: 12px;
    }
    b-popover:focus {
        outline: none !important;
        box-shadow: none;
        min-width: 800px;
    }
    .btn:focus, .btn.focus {
        outline: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none;
    }
</style>